import React from "react"
import {
    Layout, MainContainer, PersonBasicInfo,    
} from '../app/components';

const People = ({location}) => {    
    
    return(       
        <Layout location={location}>              
            <MainContainer>
                <PersonBasicInfo />                 
            </MainContainer> 
        </Layout>                                      
    )
}

export default People
